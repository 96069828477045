import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Moneyball = () => {
	const data = useStaticQuery(graphql`
		query {
			logo: file(relativePath: { eq: "portfolio/mb/mb-logo.png" }) {
				childImageSharp {
					fixed(width: 170) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			img1: file(relativePath: { eq: "portfolio/mb/01.png" }) {
				childImageSharp {
					fluid(maxWidth: 700) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			img2: file(relativePath: { eq: "portfolio/mb/02.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			img3: file(relativePath: { eq: "portfolio/mb/03.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`)

	return(
		<Layout>
			<SEO title="Case Studies: Moneyball" />
			<div className="case-study">
				<div className="intro">
					<div className="brand">
						<Img className='mx-auto' width="160" fixed={data.logo.childImageSharp.fixed} />
					</div>
					<h1 className='page-title display'>_Moneyball</h1>
					<div className="base-img">
						<Img fluid={data.img1.childImageSharp.fluid} />
					</div>
				</div>

				<div className="row text-center">
					<div className="col-md-7 mx-auto">
						<p className='text-primary-dark mb-4'>The Axe Edge team worked on a full enterprise development of a HR Technology platform. The team were involved from an early stage helping with conception through to ongoing support and feature development.</p>
						<ul>
							<li className='label label-primary mr-1'>Web Design</li>
							<li className='label label-primary mr-1'>UX Design</li>
							<li className='label label-primary mr-1'>Branding</li>
							<li className='label label-primary mr-1'>Full-Stack Development</li>
						</ul>
					</div>
				</div>

				<div className="content-img">
					<Img fluid={data.img2.childImageSharp.fluid} />
				</div>

				<div className="content-img content-img-end">
					<Img fluid={data.img3.childImageSharp.fluid} />
				</div>
			</div>
		</Layout>
	)
}


export default Moneyball
